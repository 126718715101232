<template>
  <sm-shopping-layout>
    <div class="sm-product-screen">
      <div class="sm-product-cover">
        <img v-if="!scannedProductCoverFirstThumbnailUrl" src="img/placeholder.svg" alt="Blank image">
        <img v-else :src="scannedProductCoverFirstThumbnailUrl" alt="Article cover image" />
      </div>

      <div class="sm-product-name-container">
        <h1 class="sm-product-name">{{ scannedProduct.name }}</h1>
      </div>

      <div class="sm-product-description-container">
        <span class="sm-product-short-description" v-if="scannedProduct.customFields['K_WebshopKurzbeschreibung']" v-html="scannedProduct.customFields['K_WebshopKurzbeschreibung']"></span>
        <span class="sm-product-description" v-html="scannedProduct.description"></span>
      </div>

      <div class="sm-product-information-container">
        <h3 class="sm-product-information-label">Artikelinformationen </h3>

        <ion-grid class="sm-product-information">
          <ion-row class="sm-product-information__vpe ion-justify-content-start"
                   v-if="scannedProduct.customFields && scannedProduct.customFields.UnitsPerPackage && scannedProduct.customFields.UnitsPerPackage !== ''">
            <ion-col size="auto">
              <ion-icon class="sm-product-information__vpe-icon" :icon="alertCircle" color="tertiary" ></ion-icon>
              VPE gewünscht? Dann {{ scannedProduct.customFields.UnitsPerPackage }} Stück bestellen
            </ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.productNo">
            <ion-col size="5">Produkt-Nr.:</ion-col>
            <ion-col size="7" v-if="scannedProduct.customProductNo">{{ scannedProduct.productNo }} ({{ scannedProduct.customProductNo }})</ion-col>
            <ion-col size="7" v-else>{{ scannedProduct.productNo }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.customProductNumber && scannedProduct.customProductNumber[0]">
            <ion-col size="5">Kunden-Produkt-Nr.:</ion-col>
            <ion-col size="7">{{ scannedProduct.customProductNumber[0] }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.manufacturerName">
            <ion-col size="5">Hersteller:</ion-col>
            <ion-col size="7">{{ scannedProduct.manufacturerName }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.manufacturerNo">
            <ion-col size="5">Hersteller-Nr.:</ion-col>
            <ion-col size="7">{{ scannedProduct.manufacturerNo }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.ean">
            <ion-col size="5">EAN:</ion-col>
            <ion-col size="7">{{ scannedProduct.ean }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProductPZN">
            <ion-col size="5">PZN:</ion-col>
            <ion-col size="7">{{ scannedProductPZN }}</ion-col>
          </ion-row>

          <sm-product-delivery-information v-if="scannedProduct" :product="scannedProduct" />
        </ion-grid>
      </div>

      <div v-if="scannedProductWebshopProperties.length > 0" class="sm-product-properties-container">
        <h3 class="sm-product-properties-label">Eigenschaften</h3>

        <ul class="sm-product-properties">
          <li v-for="(property, index) in scannedProductWebshopProperties" :key="index">{{ property }}</li>
        </ul>
      </div>


      <div
          class="sm-product-manufacturer-container"
          v-if="
          scannedProduct.manufacturerName ||
          (scannedProduct.manufacturerCustomFields &&
          (scannedProduct.manufacturerCustomFields['manufacturer_address'] ||
          scannedProduct.manufacturerCustomFields['manufacturer_email'] ||
          scannedProduct.manufacturerCustomFields['manufacturer_phone'])
          )"
      >
        <h3 class="sm-product-information-label">Herstellerinformationen </h3>
        <span class="sm-product-manufacturer manufacturer-name" v-if="scannedProduct.manufacturerName"><img src="img/manufacture-icon/tags.svg" alt="Blank image"> {{ scannedProduct.manufacturerName }}<br></span>
        <span class="sm-product-manufacturer manufacturer-address" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['manufacturer_address']"><img src="img/manufacture-icon/gps.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['manufacturer_address'] }}<br></span>
        <span class="sm-product-manufacturer manufacturer-email" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['manufacturer_email']"><img src="img/manufacture-icon/email.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['manufacturer_email'] }}<br></span>
        <span class="sm-product-manufacturer manufacturer-phone" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['manufacturer_phone']"><img src="img/manufacture-icon/phone.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['manufacturer_phone'] }}<br></span>
      </div>

      <div
          class="sm-product-manufacturer-container"
          v-if="
          (scannedProduct.manufacturerCustomFields &&
          (scannedProduct.manufacturerCustomFields['eu_representative_name'] ||
          scannedProduct.manufacturerCustomFields['eu_representative_address'] ||
          scannedProduct.manufacturerCustomFields['eu_representative_email'] ||
          scannedProduct.manufacturerCustomFields['eu_representative_phone'])
          )"
      >
        <h3 class="sm-product-information-label">EU Representative Informationen </h3>
        <span class="sm-product-manufacturer manufacturer-name" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['eu_representative_name']"><img src="img/manufacture-icon/tags.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['eu_representative_name'] }}<br></span>
        <span class="sm-product-manufacturer manufacturer-address" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['eu_representative_address']"><img src="img/manufacture-icon/gps.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['eu_representative_address'] }}<br></span>
        <span class="sm-product-manufacturer manufacturer-email" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['eu_representative_email']"><img src="img/manufacture-icon/email.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['eu_representative_email'] }}<br></span>
        <span class="sm-product-manufacturer manufacturer-phone" v-if="scannedProduct.manufacturerCustomFields && scannedProduct.manufacturerCustomFields['eu_representative_phone']"><img src="img/manufacture-icon/phone.svg" alt="Blank image"> {{ scannedProduct.manufacturerCustomFields['eu_representative_phone'] }}<br></span>
      </div>

    </div>
  </sm-shopping-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import SmShoppingLayout from "@/components/layout/SmShoppingLayout";
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import ScannedProductMixin from '@/mixins/scanned-product.mixin';
import {useRouter} from "vue-router";
import { alertCircle } from 'ionicons/icons';
import SmProductDeliveryInformation from '@/components/delivery-information/ProductDeliveryInformation';



export default defineComponent({
  name: 'ScannedProduct',

  components: {
    SmShoppingLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    SmProductDeliveryInformation,
  },

  mixins: [ScreenLoadingMixin, CommonMixin, ScannedProductMixin],

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  data () {
    return {
    }
  },

  // created: async function(){
  //   this.customProductNumber = await this.getCustomProductNumber();
  // },

  ionViewWillEnter() {
    this.setHeaderTitle('Gescannter Artikel');
  },

  async mounted() {
    //TODO for testing
    // this.loadProductFromApi('4ab762a72716423a9836f60d54e39b40'); // there is image
    // this.loadProductFromApi('5f50cc057d01480da4d9b92a421aba2b'); // there is no image
   // this.customProductNumber = await this.getCustomProductNumber();
  },

  computed: {
    ...mapGetters('product', ['getScannedProduct'])
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),
  }
})
</script>

<style scoped>
  .sm-product-cover {
    text-align: center;
    margin-top: 1rem;
  }

  .sm-product-cover img {
    max-height: 40vh;
  }
  .sm-product-information__vpe-icon {
    margin-right: 4px;
  }

  .sm-product-information__vpe-icon ion-icon {
    font-size: 55px;
  }

  .sm-product-description-container {
    margin-bottom: 1.5rem;
  }

  .sm-product-short-description {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm-product-information-container ion-grid {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .sm-product-information-container ion-grid ion-col {
    padding-inline-start: 0;
    padding-inline-end: 10px;
  }

  .sm-product-information-label,
  .sm-product-properties-label {
    font-size: 14px;
    font-weight: bold;
  }

  .sm-product-properties {
    padding-left: 1.5rem;
  }

  .sm-product-properties li {
    padding-bottom: 0.5rem;
  }
  .sm-product-manufacturer {
    display: flex;
    align-items:center;
    margin-bottom: 7px;
  }

  .sm-product-manufacturer img {
    margin-right: 5px;
  }

  .sm-product-manufacturer-container div:last-child {
    margin-bottom: 25px;
  }
</style>
